import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import axios from 'axios';
import '../css/phonecalltranscripts.css';

const PhoneCallTranscriptions = ({ phoneId }) => {
    const { apiUrl, hmac, loading, setLoading } = useContext(AppContext);
    const [transcriptions, setTranscriptions] = useState([]);
    const [selectedTranscription, setSelectedTranscription] = useState(null);

    useEffect(() => {
        fetchPhoneCallTranscriptions(phoneId);
    }, [phoneId]);

    const fetchPhoneCallTranscriptions = async (phoneId) => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'getPhoneCallTranscriptions');
            formData.append('phoneNumberId', phoneId);
            const response = await axios.post(apiUrl, formData);
            
            if (response.data.status === 'success') {
                const sortedTranscriptions = response.data.data.sort((a, b) => {
                    const lastMessageA = a.messages[a.messages.length - 1].timestamp;
                    const lastMessageB = b.messages[b.messages.length - 1].timestamp;
                    return new Date(lastMessageB) - new Date(lastMessageA); // Sort from most recent
                });
                setTranscriptions(sortedTranscriptions);
            } else {
                setTranscriptions(null);
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const handleClickTranscription = (transcription) => {
        console.log(transcription);
        setSelectedTranscription(transcription);
    };

    return (
        <div className="phone-call-transcriptions-container">
            {transcriptions ? (
                <div className="transcriptions-list">
                    <h2>Phone Call Transcriptions</h2>
                    <ul>
                        {transcriptions.map(transcription => (
                            <li key={transcription.conversationId} onClick={() => handleClickTranscription(transcription)}>
                                {transcription.callerPhoneNumber} - {transcription.messages[transcription.messages.length - 1].timestamp}
                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                <h4>No Phone Calls Found...</h4>
            )}

            {selectedTranscription && (
                <div className="transcription-content">
                    <div className="header-info">
                        <h3>Conversation Details</h3>
                        <p><strong>Caller Phone Number:</strong> {selectedTranscription.callerPhoneNumber}</p>
                        <p><strong>Conversation ID:</strong> {selectedTranscription.conversationId}</p>
                        <p><strong>User Phone Number:</strong> {selectedTranscription.userPhoneNumber}</p>
                        
                        {/* New: Display Conversation Length */}
                        <p><strong>Conversation Length:</strong> {selectedTranscription.conversationLength}</p>
                    </div>
                    {selectedTranscription.messages.map((message, index) => (
                        <div key={index} className={`message ${message.role}`}>
                            <strong>{message.role}</strong>
                            <p>{message.content}</p>
                            <small>{new Date(message.timestamp).toLocaleString()}</small>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default PhoneCallTranscriptions;