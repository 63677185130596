    import React, { useContext, useState } from 'react';
    import { AppContext } from '../../AppContext';
    import { useNavigate} from "react-router-dom";
    import axios from 'axios';
    import Agreement from './Agreement.js';
    import Modal from 'react-modal';
    import '../css/phonenumbers.css';

    import astro_img_1 from '../../assets/images/astro-text-image-1.jpg';
    import astro_img_2 from '../../assets/images/astro-text-image-2.jpg';
    import astro_img_3 from '../../assets/images/astro-text-image-3.jpg';
    import astro_img_4 from '../../assets/images/astro-text-image-4.jpg';
    import astro_img_5 from '../../assets/images/astro-text-image-5.jpg';


    const PhoneNumbers = () => {
        const {apiUrl, hmac, setLoading, setNewView} = useContext(AppContext);
        const navigate = useNavigate();
        const [step, setStep] = useState(1);
        const [countryCode, setCountryCode] = useState('');
        const [areaCode, setAreaCode] = useState('');
        const [availableNumbers, setAvailableNumbers] = useState([]);
        const [selectedNumbers, setSelectedNumbers] = useState([]);
        const [selectedNumber, setSelectedNumber] = useState();
        const [setShowAgreement] = useState(false);
        const [agreementAccepted, setAgreementAccepted] = useState(false);
        const [showModal, setShowModal] = useState(false);
        const [numberSelected, setNumberSelected] = useState(null);
        
        const closeModal = () => {
            setShowModal(false);
        }

        const chooseNumber = (number) => {
            setSelectedNumbers([...selectedNumbers, number]);
            setSelectedNumber(number);
        }

        const clearAllSteps = () => {
            setStep(1);
            setCountryCode('');
            setAreaCode('');
            setAvailableNumbers([]);
            setSelectedNumbers([]);
            setSelectedNumber(null);
            setAgreementAccepted(false);
            setShowModal(false);
            setNumberSelected(null);
            closeModal();
        }

        const handleNextStep = () => {
            setStep(step + 1);
        };

        const handlePreviousStep = () => {
            setStep(step - 1);
        };

        const handleSubmit = async () => {     
            setLoading(true);
            try {
                let formData = new FormData();
                formData.append('userHmacId', hmac);
                formData.append('action', 'listNumbers');
                formData.append('countryCode', countryCode);
                formData.append('areaCode', areaCode);

                const response = await axios.post(apiUrl, formData);
                if (response.data.status == 'success') {
                    setAvailableNumbers(response.data.data);
                } else {
                    alert(response.data.data);
                }

                if (availableNumbers?.length > 0 && !agreementAccepted) {
                    setShowAgreement(true);
                }
            } catch (error) {
                console.log(error);
            }

            setLoading(false);
        };

        const handleAgreementSubmit = async (agreedPhoneNumber) => {
            setLoading(true);

            try {
                let formData = new FormData();
                formData.append('userHmacId', hmac);
                formData.append('action', 'termsAgreed');
                formData.append('agreementPhone', agreedPhoneNumber);
                const response = await axios.post(apiUrl, formData);
                if (response.data.status == "success") {
                    setShowModal(true);
                    setNumberSelected(agreedPhoneNumber);
                    setAgreementAccepted(true);
                    handleNextStep();
                } else {
                    setNumberSelected(null);
                    setAgreementAccepted(false);                    
                    alert(response.data.data);
                }
            } catch (error) {
                console.log(error);
            }        
            setLoading(false);
        };

        const formatPhoneNumber = (phoneNumber) => {
            const cleaned = ('' + phoneNumber).replace(/\D/g, '');
            const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            
            if (match) {
                return '(' + match[1] + ') ' + match[2] + '-' + match[3];
            }
            
            return phoneNumber;
        };        


        return (
            <>
                <div id="stepContainer">
                    <h2>Step {step}: {step === 1 ? 'What country will you be texting from?' : 'Which Area Code would you prefer?'}</h2>
                    <div className='astrotext-images'>
                        {step === 2 ? <img src={astro_img_1} alt="AI Image 1" /> : <img src={astro_img_4} alt="AI Image 2" />}
                        {/* <img src={astro_img_3} alt="AI Image 3" />                  */}
                    </div>

                    {step === 1 && (
                        <>
                            <select
                            value={countryCode}
                            onChange={(e) => setCountryCode(e.target.value)}
                            >
                            <option value="US">US</option>
                            <option value="CA">CA</option>
                            {/* Add more options */}
                            </select>
                        </>
                    )}

                    {step === 2 && (
                        <>
                            <input
                                type="text"
                                maxLength={3}
                                size={3}
                                value={areaCode}
                                name='areaCode'
                                onChange={(e) => {
                                    const input = e.target.value;
                                    // Check if the input is a number and has a length of exactly 3
                                    if (!isNaN(input) && input.length <= 3) {
                                        setAreaCode(input);
                                    }
                                }}
                                pattern="[0-9]{3}"
                                required
                            />
                        </>
                    )}

                    <div>
                        {step > 1 && (
                            <button onClick={handlePreviousStep} style={{ marginRight: '1rem' }}>
                                Previous
                            </button>
                        )}
                        {step < 2 ? (
                            <button onClick={handleNextStep}>Next</button>
                        ) : (
                            <button type="submit" onClick={(e) => {e.preventDefault(); handleSubmit();}}>Next</button>
                        )}
                    </div>

                    {Array.isArray(availableNumbers) && availableNumbers?.length > 0 && (
                        <div className='availableNumberContainer'>
                            <h2>Available Phone Numbers:</h2>
                            <ul className='availableNumbersList'>
                                {availableNumbers?.length > 0 && availableNumbers?.map((number) => (
                                    <li key={number}>{formatPhoneNumber(number)} <button onClick={() => {chooseNumber(number);handleAgreementSubmit(number);}} >choose</button></li>
                                ))}
                            </ul>
                        </div>
                    )}

                    <Modal isOpen={showModal}>
                        {numberSelected && agreementAccepted ? (
                            <div id="stepContainer">
                                <div className='astrotext-images'>
                                    <img src={astro_img_3} alt="AI Image 3" />
                                </div>
                                <div id="congratulations">
                                    <h2>Congratulations on selecting your new number: {formatPhoneNumber(numberSelected)}!</h2>
                                    <p>Get ready to launch your first campaign. Have you already set up your contacts? If not, click the button below to proceed:</p>
                                    <button className="newBtn" onClick={() => {clearAllSteps(); setNewView('Contacts');}}>Setup your Contacts</button>&nbsp;
                                    <button className="newBtn" onClick={() => {clearAllSteps(); setNewView('Campaigns');}}>Launch A Campaign</button>&nbsp;
                                    <button className="newBtn" onClick={() => {clearAllSteps(); setNewView('Communications');}}>Setup Voice</button>
                                </div>
                            </div>
                        ):(
                            <div>There was an issue</div>
                        )}
                    </Modal>
                </div>
            </>
        );
    };

    export default PhoneNumbers;