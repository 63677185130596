import React, { useEffect, useState } from 'react';
import '../css/escalationcontactsmodal.css';

const EscalationContactsModal = ({ onClose, handleFieldChange, handleCheckboxChange, escalationSaveAndRefresh, selectedTitle, escalationContact, setEscalationContact, phoneNumberValid, validatePhoneNumber }) => {
    const [rawPhoneNumber, setRawPhoneNumber] = useState('');

    // Helper function to format phone numbers
    const formatPhoneNumber = (cleanedValue) => {
        let formattedValue = "";

        // Formatting logic
        if (cleanedValue.length >= 1) {
            formattedValue += "+1 ";
        }
        if (cleanedValue.length >= 2) {
            formattedValue += `(${cleanedValue.slice(1, 4)}) `;
        }
        if (cleanedValue.length >= 5) {
            formattedValue += cleanedValue.slice(4, 7);
        }
        if (cleanedValue.length >= 8) {
            formattedValue += '-' + cleanedValue.slice(7, 11);
        }

        return formattedValue;
    };

    const handlePhoneNumberChange = (e) => {
        const { name, value } = e.target;
        setRawPhoneNumber(value); // Store the raw input

        // Check if the input is empty or consists of valid phone characters
        if (value === '' || /^[\d+()\s-]*$/.test(value)) {
            const cleanedValue = value.replace(/\D/g, ''); // Remove non-digit characters
            const formattedValue = formatPhoneNumber(cleanedValue); // Format the cleaned value

            // Update escalationContact state if the input changes
            if (formattedValue !== escalationContact?.[name]) {
                setEscalationContact((prevContact) => ({
                    ...prevContact,
                    [name]: formattedValue // Update state with the formatted value
                }));

                validatePhoneNumber(formattedValue); // Validate the phone number
            }
        }
    };

    useEffect(() => {
        if (escalationContact) {
            const phoneNumber = escalationContact.escalation_phone_number || '';
            const cleanedValue = phoneNumber.replace(/\D/g, '');  // Clean the phone number to work with
            const formattedPhoneNumber = formatPhoneNumber(cleanedValue); // Format the cleaned number
            setRawPhoneNumber(formattedPhoneNumber); // Set the state to the formatted version
            validatePhoneNumber(formattedPhoneNumber); // Validate the phone number
        }
    }, [escalationContact]); // Depend on escalationContact changes

    return (
        <div className="modal">
            <div className="modal-content">
                {escalationContact?.escalation_id ? (
                    <h2>Edit Escalation Contact</h2>
                ) : (
                    <h2>Add Escalation Contact</h2>
                )}

                <label>
                    Title:
                    <select
                        name="escalation_title"
                        value={escalationContact?.escalation_title}
                        onChange={handleFieldChange}
                        required
                    >
                        <option value="">Select Title</option>
                        <option value="Manager">Manager</option>
                        <option value="Supervisor">Supervisor</option>
                        <option value="Team Leader">Team Leader</option>
                        <option value="Support Specialist">Support Specialist</option>
                        <option value="Technical Lead">Technical Lead</option>
                        <option value="Executive">Executive</option>
                        <option value="Director">Director</option>
                        <option value="Coordinator">Coordinator</option>
                        <option value="Officer">Officer</option>
                        <option value="Analyst">Analyst</option>
                        <option value="Administrator">Administrator</option>
                        <option value="Other">Other</option>
                    </select>
                    {selectedTitle === "Other" && (
                        <input
                            type="text"
                            name="customTitle"
                            value={escalationContact?.customTitle}
                            onChange={handleFieldChange}
                            placeholder="Enter a title of your choice..."
                        />
                    )}
                </label>

                <label>
                    First Name:
                    <input
                        type="text"
                        name="escalation_first_name"
                        value={escalationContact?.escalation_first_name}
                        onChange={handleFieldChange}
                        required
                    />
                </label>

                <label>
                    Last Name:
                    <input
                        type="text"
                        name="escalation_last_name"
                        value={escalationContact?.escalation_last_name}
                        onChange={handleFieldChange}
                        required
                    />
                </label>

                <label>
                    Email:
                    <input
                        type="email"
                        name="escalation_email"
                        value={escalationContact?.escalation_email}
                        onChange={handleFieldChange}
                        required
                    />
                </label>

                <label>
                    Phone Number:
                    <input
                        type="tel"
                        name="escalation_phone_number"
                        value={rawPhoneNumber} // Update value with raw phone number for editing
                        onChange={handlePhoneNumberChange}
                        required
                    />
                    {!phoneNumberValid && <span className="error-message">Phone number must be in the given format: +1 (888) 888-9999</span>}
                </label>

                <label>
                    Accepts Text Messages:
                    <input
                        type="checkbox"
                        name="accepts_text_message"
                        checked={escalationContact.accepts_text_message === '1'}
                        onChange={handleCheckboxChange}
                    />
                </label>

                <div className="modal-buttons">
                    <button type="button" className='newBtn' onClick={escalationSaveAndRefresh}>Save</button>
                    <button type="button" className='newBtn' onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default EscalationContactsModal;