import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import axios from 'axios';
import PhoneCallTranscriptions from './phoneCallTranscriptions';
import '../css/incoming.css';

const Incoming = () => {
    const { apiUrl, hmac, loading, setLoading, selectedContactPhone } = useContext(AppContext);

    const [outgoingPhoneNumber, setOutgoingPhoneNumber] = useState('');
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');
    const [selectedPhoneId, setSelectedPhoneId] = useState(selectedContactPhone?.user_phone_id);
    const [showCommunicationTypes, setShowCommunicationTypes] = useState(true);
    const [selectedCommunicationType, setSelectedCommunicationType] = useState('calls');
    const [activeConversation, setActiveConversation] = useState(null);
    const [conversations, setConversations] = useState([]);
    const [currentConversation, setCurrentConversation] = useState([]);
    const [myPhoneNumber, setFromPhone] = useState(null);
    const [newMessage, setNewMessage] = useState('');
    const [isNewMessageMode, setIsNewMessageMode] = useState(false); // New state for toggling
    const [userPhoneNumbers, setUserPhoneNumbers] = useState([]);

    useEffect(() => {
        fetchUserPhoneNumbers();
    }, []);

    useEffect(() => {
        if (selectedPhoneNumber) {
            fetchConversationsFromAPI(selectedPhoneNumber);
        }
    }, [selectedPhoneNumber]);

    const fetchUserPhoneNumbers = async () => {
        try {
            const formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'listMyPhoneNumbers'); // Adjust action name to what your API expects
            const response = await axios.post(apiUrl, formData);

            if (response.data.status === 'success') {
                setUserPhoneNumbers(response.data.data); // Assuming data contains an array of phone numbers
            } else {
                setUserPhoneNumbers([]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSelectPhoneNumber = (phoneId) => {
        setSelectedPhoneId(phoneId); // Set the selected phone number
        // setSelectedPhoneNumber(e.target.value); // Set the selected phone number
        setActiveConversation(null); // Reset conversation when changing phone number
        setCurrentConversation([]); // Reset current conversation
    };



    const fetchConversationsFromAPI = async (phoneId) => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'getIncoming');
            formData.append('phoneNumberId', phoneId);
            const response = await axios.post(apiUrl, formData);

            if (response.data.status === 'success') {
                const data = response.data.data;
                const apiConversations = Object.values(data);

                // Sort conversations by the most recent activity
                const sortedConversations = apiConversations.sort((a, b) => {
                    const lastMessageA = a.messages?.length ? a.messages[a.messages.length - 1].timestamp : 0;
                    const lastMessageB = b.messages?.length ? b.messages[b.messages.length - 1].timestamp : 0;
                    return new Date(lastMessageB) - new Date(lastMessageA); // Sort from most recent
                });

                setConversations(sortedConversations);
            } else {
                setConversations([]);
            }

            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const fetchCompleteConversation = async (phoneNumber) => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'getConversation');
            formData.append('conversationPhoneNumber', phoneNumber);
            const response = await axios.post(apiUrl, formData);
            const completeConversation = Object.values(response.data.data ?? []);
            setCurrentConversation(completeConversation);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const handleSelectCommunicationType = (type) => {
        setSelectedCommunicationType(type);
        type === 'sms' ? fetchConversationsFromAPI(selectedPhoneId) : setActiveConversation(null);
    };

    const handleNewMessage = async () => {
        if (outgoingPhoneNumber.trim() !== '' && newMessage.trim() !== '') {
            try {
                setLoading(true);
                const formData = new FormData();
                formData.append('userHmacId', hmac);
                formData.append('action', 'sendSMS');
                formData.append('phoneNumberId', selectedPhoneId);
                formData.append('conversationPhoneNumber', outgoingPhoneNumber);
                formData.append('smsMessage', newMessage);
                const response = await axios.post(apiUrl, formData);
                // Optionally, you can fetch conversations again to update the list after sending a message
                fetchConversationsFromAPI(selectedPhoneId);
                setOutgoingPhoneNumber('');
                setNewMessage('');
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
    };

    const handleSendMessage = async () => {
        if (newMessage.trim() !== '') {
            try {
                setLoading(true);
                const formData = new FormData();
                formData.append('userHmacId', hmac);
                formData.append('action', 'sendSMS');
                formData.append('phoneNumberId', selectedPhoneId);
                formData.append('conversationPhoneNumber', activeConversation);
                formData.append('smsMessage', newMessage);
                const response = await axios.post(apiUrl, formData);
                // Fetch the current conversation again to display the updated messages
                fetchCompleteConversation(activeConversation);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
            setNewMessage('');
        }
    };

    const handleClickConversation = async (conversationId, phoneNumber) => {
        setActiveConversation(phoneNumber); // Update activeConversation to display the selected phone number
        fetchCompleteConversation(phoneNumber);
    };

    const toggleNewMessageMode = () => {
        setIsNewMessageMode(!isNewMessageMode);
        // Reset the fields when switching modes
        if (isNewMessageMode) {
            setOutgoingPhoneNumber('');
            setNewMessage('');
        }
    };

    return (
        <div id="incomingContainer" className='content_container'>
            {showCommunicationTypes && (
                <div className="communication-types">
                    <div>
                        <button onClick={() => handleSelectCommunicationType('sms')} disabled>
                            View Text Messages
                        </button>
                        <button onClick={() => handleSelectCommunicationType('calls')}>
                            View Phone Call Transcriptions
                        </button>
                    </div>
                </div>
            )}
            <h3>Select Phone Number for Transcripts</h3>
            <div className="phone-number-bubbles">
                {userPhoneNumbers.map(phone => (
                    <div 
                        key={phone.user_phone_id} 
                        className={`phone-number-bubble ${selectedPhoneNumber === phone.user_phone_id ? 'active' : ''}`} 
                        onClick={() => handleSelectPhoneNumber(phone.user_phone_id)}
                    >
                        {phone.phone_number}
                    </div>
                ))}
            </div>         
            {showCommunicationTypes && selectedCommunicationType === 'sms' && (
                <div className="conversation-container">
                    <button onClick={toggleNewMessageMode}>
                        {isNewMessageMode ? 'Back to Conversations' : 'Start New Conversation'}
                    </button>
                    {isNewMessageMode ? (
                        <div className="new-message-container">
                            <h3>Send a New Message</h3>
                            <input
                                type="text"
                                value={outgoingPhoneNumber}
                                onChange={e => setOutgoingPhoneNumber(e.target.value)}
                                placeholder="Enter outgoing phone number"
                            />
                            <input
                                type="text"
                                value={newMessage}
                                onChange={e => setNewMessage(e.target.value)}
                                placeholder="Type your message"
                            />
                            <button onClick={handleNewMessage}>Send New Message</button>
                        </div>   
                    ) : (
                        <>
                            <div className="conversations-sidebar">
                                {conversations.length > 0 ? (
                                    <ul>
                                        {conversations.map(conversation => (
                                            <li key={conversation.id}>
                                                <button className="conversation-button" onClick={() => handleClickConversation(conversation.id, conversation.from)}>
                                                    {conversation.from}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                ) : ("No Text Messages Found...")}
                            </div>

                            {activeConversation ? (
                                <div className="conversation-content">
                                    <h2>SMS Conversation With: {activeConversation}</h2>
                                    <div className="messages">
                                        {currentConversation.map(message => (
                                            <div key={message.id} className={`message ${message.from === activeConversation ? 'received' : 'sent'}`}>
                                                <strong>{message.from} ({message.msg_UTC}): </strong>
                                                <p>{message.body}</p>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="message-input">
                                        <input
                                            type="text"
                                            value={newMessage}
                                            onChange={e => setNewMessage(e.target.value)}
                                            placeholder="Type your reply"
                                        />
                                        <button onClick={handleSendMessage}>Send</button>
                                    </div>
                                </div>      
                            ) : (
                                <div>Please select a conversation</div>
                            )}
                        </>
                    )}
                </div>
            )}
            {selectedCommunicationType === 'calls' && (
                <div className="phone-call-transcriptions">
                    <PhoneCallTranscriptions phoneId={selectedPhoneId} />
                </div>
            )}
        </div>
    );
};

export default Incoming;